.header-container {
    padding: 20px 0;
    background-repeat: no-repeat; 
    background-position: center; 
    height: 200px;
    min-height: 200px;
}


.logo-text {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 39px;
    letter-spacing: -1.5%;
    text-align: center;
    color: white;
}

.show_more {
    background: #C69A52;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%; /* center the button horizontally */
    top: 50%; 
    transform: translate(-50%, -65%); /* 50% centers it, additional 5% moves it up */
    z-index: 10;
}


.wall-image-container {
	margin-top: -50px;
    display: flex; 
    height: 160px;
    width: 100%;
    align-items: center;
	overflow: hidden; /* Hide overflow to prevent any gap issues */
}

.tower-image {
    width: 200px; /* Fixed width for the towers */
    height: 160px; /* Setting a fixed height based on tower's height */
    object-fit: cover;
}

.stretch-wall-background {
    margin-top: 100px;
    flex-grow: 1;
    width: 105%;
    height: 93px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: scaleX(1.05);
}


@media (max-width: 1300px) {
    .stretch-wall-image {
        background-size: contain; /* This makes sure the image is contained within the div and doesn't get cropped */
    }
}



.wall-image {
    display: block;
    width: auto; /* let it adjust automatically */
    height: 160px; /* tower height, as given */
    max-width: none; /* important to override any max-width settings */
    object-fit: cover; /* to ensure it covers the width */
    object-position: center; /* to keep it centered */
}



.sub-text1, .sub-text2 {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}

.sub-text1 {
    letter-spacing: 30%;
	top: 14%;
}

.sub-text2 {
    letter-spacing: 0;
	top: 42%;
}


.search-input {
    height: 30px;
    margin-top: 10px;
    border-radius: 10px;
    margin-right: 0;
}

.search-book-list {
    position: absolute;
    right: 9%;
    top: 240px;
    background: #fff;
    width: 150px;
    max-height: 150px; /* Adjust as needed */
    overflow-y: hidden;
  }
  
  .search-book-name {
    width: 100px;
    padding: 8px;
    cursor: pointer;
    overflow: hidden;
  }
  
  .search-book-name:hover {
    background: #f5f5f5;
  }
  

.find-button {
	height: 28px;
	margin-left: -55px;
    border-radius: 10px;
    background-color: #C69A52;
    padding: 5px 10px;
    border: none;
    color: white;
}


.nav-text {
	font-family: 'Philosopher', sans-serif;
    font-weight: bold;
	color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s; /* This will smoothly change the background color */
	padding-top: 5px;
    padding-right: 25px;
    font-size: 36px;
	text-decoration: none;
}

.nav-text:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Slight highlight on hover */
}

.header-categories-bg {
	width: 100%;
    background-image: url('../../public/mainBackground.png');
	background-size: cover;
    background-repeat: repeat-x;
    background-position: center;
}

.header-bg-default {
    padding-bottom: 20px;
    width: 100%;
    background-image: url('../../public/mainBackground.png');
    background-size: fill;
    background-repeat: repeat-x;
    background-position: center;
    /* Add any additional styles or modify the existing ones as needed */
}

.header-categories-bg, .header-bg-default {
    width: 100%;
    background-image: url('../../public/mainBackground.svg');
    background-size: 100% auto;  /* This will set the width to 100% of the container and height will adjust automatically to maintain the image's aspect ratio */
    background-repeat: no-repeat;  /* This ensures the background doesn't repeat */
    background-position: top center;  /* This will position the background image to the top center of the container */
}

.header-categories-bg, .header-bg-default {
    width: 100%;
    background-image: url('../../public/mainBackground.png');
    
    /* Set the background size to the actual image size */
    background-size: 3000px auto; 
    background-repeat: no-repeat;
    background-position: center top; 
}