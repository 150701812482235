.custom-button {
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
	position: relative;
}


.highlight-line {
    position: absolute; 
    bottom: -18px;
    height: 5px;
	left: 0;
	right: 0;
    background: #C69A52;
    transition: transform 0.3s ease;
	z-index: 2;
}

@media (max-width: 992px) {
    .highlight-line {
        bottom: -12px;
    }
}

.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10%;
	padding-top: 40px
}

.filter-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
	width: 50%;
	position: relative;
}

.filter-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    color: grey;
    transition: color 0.3s ease;
	margin-right: 0%;
}

.filter-buttons button:last-child {
    margin-right: 0;
}

.filter-buttons button.active {
    color: #C69A52;
}

.dropdown-container {
    position: relative;
	margin-right: 20%;
    display: inline-block;
}

.dropdown-icon {
    position: absolute;
    right: 20px; /* Adjust based on your design */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Make it unclickable */
}


.category-dropdown {
	text-align: center;
    width: 200px;
    height: 45px;
    background-color: white;
    border: 1px solid #C69A52;
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
	border-radius: 12px;
}


.gray-line {
    height: 1px;
    background: grey;
    position: relative;
    margin-left: 10%;
	margin-right: 40%;
	z-index: 1;
}



.books-list {
	padding-left: 8%;
	padding-right: 8%;
	padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
}

.books-list a {
    text-decoration: none; /* remove underline from all links inside the books-list */
    color: inherit; /* should make the link take the color of its parent element */
}

.books-list a:visited {
    color: inherit; 
}

.books-list a:hover .book-name {
    background-color: rgba(255, 255, 255, 0.1);
}

.book img {
    width: 260px;
    height: 360px;
    object-fit: cover;
}


.book {
    width: 260px;
    margin: 20px;
    position: relative;
}

.book-year {
    position: absolute;
    top: -40px;
    left: 0;
    width: 78px;
    height: 34px;
    background-color: blue;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 12px;
	font-size: 16px;
    font-family: 'Oswald', sans-serif;
}

.book-name {
    max-width: 260px;
    text-overflow: ellipsis;
    overflow: visible;
    white-space: normal
}


