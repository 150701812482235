.footer-container {
    background-color: #232B35;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .footer-text {
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    color: antiquewhite;
  }
  