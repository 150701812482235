/* Importing the Philosopher font */
@font-face {
    font-family: 'Philosopher';
    src: url('../fonts/Philosopher-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Philosopher';
    src: url('../fonts/Philosopher-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Philosopher';
    src: url('../fonts/Philosopher-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Philosopher';
    src: url('../fonts/Philosopher-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}




@font-face {
    font-family: 'Oswald';
    src: url('../fonts/Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/Oswald-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
