.categoriesSection {
    padding-top: 60px;
    margin-bottom: 50px;
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.categoriesText {
    padding-left: 5%;
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 26px;
    color: #FFF;
}

.category-image-button {
    width: 255px;
    height: 156px;
    border-radius: 15px;
    border: 2.5px solid #FFF;
    background: linear-gradient(0deg, #171717 -7.05%, rgba(0, 0, 0, 0.00) 87.18%, rgba(16, 15, 15, 0.00) 87.18%), lightgray 50% / cover no-repeat;
    margin: 0 calc(3% / 2);
	background-size: contain;
    position: relative;  /* Added to position the child element (category name) */
    overflow: hidden;    /* Keep everything within the rounded corners */
}

.category-name {
    position: absolute;
    bottom: 10px;       /* 10px padding from the bottom */
    left: 50%;          /* Center horizontally */
    transform: translateX(-50%); /* Shift back by half of its width to truly center */
    color: #FFF;        /* Assuming white color for the text */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background for better visibility */
    padding: 2px 5px;  /* Some padding around the text */
    border-radius: 10px; /* Rounded corners for the text background */
	font-size: 18px;
    font-family: 'Oswald', sans-serif;
}

.category-image-button:hover {
    transform: scale(1.05);
}

.categories-row {
    display: flex;
    justify-content: space-between;
    /* padding: 10px 20px; */
    padding-left: 5%;
    padding-right: 5%;
    gap: 3%;
}
