.section-container {
    position: relative;
}

.categories-container {
    position: relative;
}

.show_more {
    background: #C69A52;
    border-radius: 50px;
    width: 40px; 
    height: 40px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; 
    left: 50%; 
    top: calc(470px);
    transform: translateX(-50%);
    z-index: 10;
}

.show_more.expanded {
    top: calc(830px);
}
