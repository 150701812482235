.row-container-top {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 30px;
}

.row-container-top::before {
    content: '';
    position: absolute;
    height: 1px;
    /* or however thick you want the line to be */
    background-color: black;
    /* or any color you want for the line */
    width: 45vw;
    /* 45% of the viewport width */
    left: 0;
}

.icon-line-wrapper {
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    /* Ensure the items are centered */
    align-items: flex-end;
    position: relative;
}

.icon-line-wrapper::after {
    content: '';
    height: 1px;
    /* Adjust this for the line thickness */
    background: #000;
    /* Adjust this for the line color */
    width: 45%;
    margin-left: 10px;
    /* Spacing between icon and line */
}

.center-icon {
    display: flex;
    padding-right: 4%;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* to place it on top of the line */
}




.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* takes full width of the parent to center the icon */
    z-index: 1;
    /* to place it on top of the line */
}

img {
    /*width:
         specify your icon width here
    ;*/
    height: auto;
}


.quoteText {
    align-items: center;
    text-align: center;
    padding-left: 30%;
    padding-right: 30%;
    font-size: 20px;
    font-family: 'Philosopher', sans-serif;
    font-weight: normal;
}

.quoteAuthor {
    align-items: center;
    text-align: center;
    padding-left: 30%;
    padding-right: 30%;
    font-size: 20px;
    font-family: 'Philosopher', sans-serif;
    font-style: italic;
    font-weight: bold;
}

.quoteAuthorFirstWord {
    font-weight: normal;
}