.book-image {
    width: 260px;
    height: 360px;
	overflow: hidden;
    object-fit: fill;
}

.book-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
}

.book-detail-row {
    display: flex;
    padding-bottom: 10px;
}

.book-detail-label {
    flex-basis: 250px;
    color: #858585;
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 26px;
}

.book-detail-content {
    color: #343434;
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 26px;
	max-width: calc(100% - 250px);
	white-space: nowrap;
	text-overflow: ellipsis;
}

.description {
	padding-top: 50px;
    color: #343434;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 26px;
}

.book-description {
	padding-right: 5%;
    color: #343434;
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 20px;
}