.books-for-sale-widget {
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 5%;
	padding-right: 5%;
}

.allBooksText {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 26px;
}


.books-for-sale-list {
	max-width: 100vw;
	margin: 0 auto;
	display: flex;
	overflow-x: auto;
	gap: 10px;
	white-space: nowrap;
	scroll-behavior: smooth;
	position: relative;
	scrollbar-width: none;
}

.books-for-sale-list::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
}

.allBooksButton {
	padding-right: 5%;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 26px;
    color: #C69A52;
    outline: none; /* To remove the default focus outline, optional */
    transition: color 0.3s; /* Optional: Smooth color transition on hover */
}

.allBooksButton:hover {
    color: #A47844; /* A slightly darker shade on hover, adjust as needed */
}


.book-image-button {
	flex-shrink: 0;
	width: 260px;
	height: 360px;
	background: linear-gradient(0deg, #171717 -7.05%, rgba(0, 0, 0, 0.00) 87.18%, rgba(16, 15, 15, 0.00) 87.18%), lightgray 50% / cover no-repeat;
	cursor: pointer;
	border-radius: 15px; 
	transition: transform 0.3s;
	position: relative;
	overflow: hidden;
}

.book-image-button img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
}


.book-image-button:hover {
	transform: scale(1.05);
}

.scroll-button {
	background: #C69A52;
	border-radius: 50px;
	width: 40px;
	height: 40px;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 10;
	bottom: -50px;
	background-repeat: no-repeat;
    background-position: center;
}

.scroll-button.scroll-button-left {
    left: 30px;
    background-image: url('../../public/left.svg');
}

.scroll-button.scroll-button-right {
    right: 30px;
    background-image: url('../../public/right.svg');
}
