.contacts-content {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
}

.centered-text {
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
}

.row {
    display: flex;
    width: 100%;
    padding: 3px 0;
}

.first-text {
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 20px;
    color: #C69A52;
}

.second-text {
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 20px;
    padding-left: 10px;
    color: black;
}

.final-text {
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: start;
    padding-bottom: 20px; /* Add some spacing above the final text */
    display: flex;
    width: 100%;
}
